.Inputs:focus {
  /* outline: none; */
  border: 1px solid black;
  box-shadow: 0 0 5px black;
  transition: all 0.3s ease;
}

.Inputs:hover {
  border: 1px solid black;
  box-shadow: 0 0 5px black;
  transition: all 0.3s ease;
}

.Inputs {
  font-family: 'Roboto', sans-serif !important;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  transition: all 0.3s ease;
  height: 40px;
}

.Title {
  font-family: 'Roboto', sans-serif !important;
}

.ant-select-selector:hover {
  border: 1px solid black !important;
  box-shadow: 0 0 5px black !important;
  transition: all 0.3s ease !important;
}

.ant-select-selector:focus {
  border: 1px solid black !important;
  box-shadow: 0 0 5px black !important;
  transition: all 0.3s ease !important;
}

.ant-select-selector {
  border-radius: 5px !important;
  border: 1px solid #ccc !important;
  padding: 10px !important;
  width: 100% !important;
  transition: all 0.3s ease !important;
  height: 40px !important;
  font-family: 'Roboto', sans-serif !important;
}

.ant-select-item-option-content {
  font-family: 'Roboto', sans-serif !important;
}

.payment-options {
  display: flex;
  /* justify-content: space-around; */
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.payment-option {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.payment-option h3 {
  font-size: 16px;
  margin-bottom: 0;
}

.payment-option.selectedType {
  border-color: black;
  background-color: #f5f5f5;
}


.billing-addresses {
  display: flex;
  /* justify-content: space-around; */
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.billing-address {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.billing-address h3 {
  font-size: 16px;
  margin: 0;
  float: left;
}

.billing-address.selectedType {
  border-color: black;
  background-color: #f5f5f5;
}

.checkoutItemsContainer {
  color: black;
  /* max-height: 450px; */
  width: 100%;
}


/* Container for each cart item */
.cartItem {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid black;
  /* Add a light border between items */
}

/* Image styles */
.cartItem img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 20px;
  border: 2px solid black;
  /* Add a light border around the image */
  border-radius: 5px;
  /* Rounded corners */
}

/* Details section */
.itemDetails {
  flex: 1;
  /* Take remaining space */
}

.itemDetails h3 {
  margin: 0;
  font-size: 18px;
  color: black;
  /* Darker text color */
  font-weight: 700;
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;
}

.itemDetails h3.size {
  margin: 0;
  font-size: 12px;
  color: black;
  /* Darker text color */
  font-family: 'Roboto', sans-serif;
}

.itemDetails h3.color {
  margin: 0;
  font-size: 12px;
  color: black;
  /* Darker text color */
  font-family: 'Roboto', sans-serif;
}

.itemDetails p {
  margin: 0;
  font-size: 14px;
  color: black;
  /* Lighter text color */
  font-family: 'Roboto', sans-serif;
}

/* Size details */
.size {
  font-size: 14px;
  color: #555;
  /* Medium text color */
  margin-bottom: 5px;
  /* Add space between size and quantity controls */
}

/* Quantity controls */
.quantityControls {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  padding-top: 10px;
  /* Adding top padding */
}

.quantityContainer {
  display: flex;
  align-items: center;
  border: 2px solid black;
  /* White border */
  border-radius: 5px;
  /* Rounded corners */
  background-color: #000000;
  /* Black background */
  width: 95px;
}

.quantityButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: white;
  transition: color 0.3s;
  /* Smooth transition for text color */
}

.quantityButton:hover {
  color: #999;
  /* Darker text color on hover */
}

.quantity {
  font-size: 12px;
  margin: 0 20px;
  padding-bottom: 2px;
  padding-top: 1px;
  color: white;
  /* Text color */
}

/* Remove button */
.removeButton {
  background-color: rgb(110, 110, 110);
  /* Red background */
  color: white;
  border: none;
  padding: 8px 15px;
  /* Adjust padding */
  border-radius: 5px;
  /* Rounded corners */
  cursor: pointer;
  transition: background-color 0.3s;
  /* Smooth transition for background color */
}

.removeButton:hover {
  background-color: #d14646;
  /* Darker red on hover */
}

.removeButtonContainer {
  float: right;
  /* Align to the right */
  align-self: flex-end;
}

.submitBTN:hover {
  color: white !important;
}

.submitBTN:disabled {
  background-color: #ccc !important;
  color: white !important;
}

.rightCheckout {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-left: 20px;
  padding-right: 80px;
}

.leftCheckout {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-left: 80px;
  padding-right: 20px;
}

@media only screen and (max-width: 1000px) {
  .rightCheckout {
    padding-left: 0;
    padding-right: 0;
    width: 90%;
  }

  .leftCheckout {
    padding-left: 0;
    padding-right: 0;
    width: 90%;
  }
}
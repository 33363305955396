.cart {
    position: fixed;
    top: 0;
    right: -400px; /* Initially hidden */
    height: 100%;
    width: 400px;
    background-color: #000000;
    z-index: 1000;
    transition: right 0.3s ease-in-out;
    color: white; /* Set text color to white */
    overflow-y: auto; /* Allow vertical scrolling */
}

.cart.open {
    right: 0; /* Slides in when open */
}

.cartContent {
    padding: 20px;
}

.cartHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
}

.cartHeader h2 {
    margin: 0;
}

.cartHeader svg {
    cursor: pointer;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0); /* Transparent by default */
    z-index: 999; /* Ensure the overlay appears behind the cart */
    display: none; /* Initially hidden */
    transition: background-color 0.3s ease-in-out; /* Transition for smooth effect */
}

.overlayVisible {
    background-color: rgba(255, 255, 255, 0.6); /* Semi-transparent black background */
    display: block; /* Display overlay when cart is open */
}

.orderNoteContainer {
    position: relative; /* Set to relative */
    margin-top: 40px; /* Adjust as needed */
    width: calc(100% - 40px); /* Take full width minus 40px (20px padding on each side) */
}

.orderNoteLabel {
    font-size: 16px;
    color: #ffffff;
    font-family: 'Roboto', sans-serif;
    position: absolute; /* Position relative to the container */
    top: -30px; /* Adjust as needed */
    left: 0; /* Align to the left */
}

.orderNote {
    width: 100%;
    height: 40px;
    resize: none;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    font-size: 12px;
    color: #333;
    padding: 10px; /* Add padding to all sides */
    font-family: 'Roboto', sans-serif;
}

.buttonContainer {
    position: absolute;
    bottom: 20px; /* Adjust as needed */
    left: 50%;
    transform: translateX(-50%);
}

.checkoutButton {
    appearance: button;
    background-color: #000;
    background-image: none;
    border: 1px solid #ffffff;
    border-radius: 4px;
    box-shadow: #fff 4px 4px 0 0,#000 4px 4px 0 1px;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin: 0 5px 10px 0;
    overflow: visible;
    padding: 12px 80px;
    text-align: center;
    text-transform: none;
    touch-action: manipulation;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
    white-space: nowrap;
}

.checkoutButton:focus {
    text-decoration: none;
}

.checkoutButton:hover {
    text-decoration: none;
}

.checkoutButton:active {
    box-shadow: rgba(0, 0, 0, .125) 0 3px 5px inset;
    outline: 0;
}

.checkoutButton:not([disabled]):active {
    box-shadow: #fff 2px 2px 0 0, #000 2px 2px 0 1px;
    transform: translate(2px, 2px);
}

@media (min-width: 768px) {
    .checkoutButton {
        padding: 12px 50px;
    }
}

/* Style for cart items container */
.cartItemsContainer {
    max-height: 370px; /* Set maximum height for scrollability */
    overflow-y: auto; /* Enable vertical scrolling */
    overflow-x: hidden; /* Disable horizontal scrolling */
    scrollbar-width: thin; /* Set the width of the scrollbar */
    padding-right: 20px;
}

/* .cartItemsContainer::-webkit-scrollbar {
    width: 2px; /* Set the width of the scrollbar */
/* } */ 

.subtotalContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

/* Styles for subtotal text */
.subtotalText {
    font-size: 16px;
    color: #ffffff;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
}

/* Styles for subtotal amount */
.subtotalInt {
    font-size: 16px;
    color: #ffffff;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    /* Add any additional styles for the subtotal amount */
}

.shipping{
    font-size: 12px;
    color: #ffffff;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    padding-top: 5%;
    text-align: center;
}

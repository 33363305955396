.carousel-container img {
  max-width: 100%;
  height: auto;
}

.carousel-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 auto;
  width: 100%;
}

.checkoutButton {
  appearance: button;
  background-color: #000;
  background-image: none;
  border: 1px solid #ffffff;
  border-radius: 4px;
  box-shadow: #fff 4px 4px 0 0, #000 4px 4px 0 1px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0 5px 10px 0;
  overflow: visible;
  padding: 12px 80px;
  text-align: center;
  text-transform: none;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

.checkoutButton:focus {
  text-decoration: none;
}

.checkoutButton:hover {
  text-decoration: none;
}

.checkoutButton:active {
  box-shadow: rgba(0, 0, 0, 0.125) 0 3px 5px inset;
  outline: 0;
}

.checkoutButton:not([disabled]):active {
  box-shadow: #fff 2px 2px 0 0, #000 2px 2px 0 1px;
  transform: translate(2px, 2px);
}

@media (min-width: 768px) {
  .checkoutButton {
    padding: 12px 50px;
  }
}

.voucher-table {
  width: 1200px; /* Adjust this value to your desired table width */
  border: 1px solid #eee;
  border-collapse: collapse;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
  }
  
  .voucher-table th,
  .voucher-table td {
    border: 1px solid #eee;
    padding: 15px; /* Slightly increased padding */
    width: 200px;
  }
  
  .voucher-table th {
    background-color: #f5f5f5; /* Lighter background for headers */
    font-weight: bold;
    text-align: left; /* Left-align header text by default */
  }
  
  .voucher-table img {
    max-width: 100%;
    height: auto;
    border-radius: 5px; /* Add subtle rounded corners */
  }
  
  .voucher-table .ant-pagination {
    justify-content: flex-end;
    margin-top: 10px; /* Add spacing above pagination */
  }
  
  .voucher-table .ant-btn {
    margin: 0 5px;
    border-radius: 4px; /* Add rounded corners to buttons */
  }
  
  /* Modal */
.voucher-modal {
    font-family: Arial, sans-serif;
  }
  
  .voucher-modal .ant-modal-content {
    border-radius: 8px;
  }
  
  .voucher-modal .ant-modal-header {
    background-color: #1890ff;
    color: #fff;
    border-radius: 8px 8px 0 0;
  }
  
  .voucher-modal .ant-modal-title {
    color: #fff;
  }
  
  .voucher-modal .ant-modal-body {
    padding: 24px;
  }
  
  .voucher-modal .ant-modal-footer {
    border-radius: 0 0 8px 8px;
  }
.collection-table {
    width: 1200px; /* Adjust this value to your desired table width */
    border: 1px solid #eee;
    border-collapse: collapse;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow-x: auto;
  }
  
  .collection-table th,
  .collection-table td {
    border: 1px solid #eee;
    padding: 15px; /* You can adjust padding using media queries */
    word-wrap: break-word;
    width: 50px;
  }
  
  .collection-table th {
    background-color: #f5f5f5;
    font-weight: bold;
    text-align: left;
  }
  
  .collection-table img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
  }
  
  .collection-table .ant-pagination {
    justify-content: flex-end;
    margin-top: 10px;
  }
  
  .collection-table .ant-btn {
    margin: 0 5px;
    border-radius: 4px;
  }
  
  /* Optional media query to adjust padding on smaller screens */
  @media (max-width: 768px) {
    .collection-table th,
    .collection-table td {
      width: 150px;
      padding: 10px;
    }
  }
  
  /* Optional media query to adjust font size on smaller screens */
  @media (max-width: 768px) {
    .collection-table th,
    .collection-table td {
      font-size: 0.9em; /* Adjust font size as needed */
    }
  }
  
/* Container for each cart item */
.cartItem {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid black; /* Add a light border between items */
}

/* Image styles */
.cartItem img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    margin-right: 20px;
    border: 2px solid black; /* Add a light border around the image */
    border-radius: 5px; /* Rounded corners */
}

/* Details section */
.itemDetails {
    flex: 1; /* Take remaining space */
}

.itemDetails h3 {
    margin: 0;
    font-size: 18px;
    color: black; /* Darker text color */
    font-weight: 700;
    text-transform: uppercase;
    font-family: 'Roboto', sans-serif;
}

.itemDetails h3.size {
    margin: 0;
    font-size: 12px;
    color: black; /* Darker text color */
    font-family: 'Roboto', sans-serif;
}

.itemDetails h3.color {
    margin: 0;
    font-size: 12px;
    color: black; /* Darker text color */
    font-family: 'Roboto', sans-serif;
}

.itemDetails p {
    margin: 0;
    font-size: 14px;
    color: black; /* Lighter text color */
    font-family: 'Roboto', sans-serif;
}

/* Size details */
.size {
    font-size: 14px;
    color: #555; /* Medium text color */
    margin-bottom: 5px; /* Add space between size and quantity controls */
}

/* Quantity controls */
.quantityControls {
    display: flex;
    align-items: center;
    padding-top: 10px; /* Adding top padding */
}

.quantityContainer {
    display: flex;
    align-items: center;
    border: 2px solid white; /* White border */
    border-radius: 5px; /* Rounded corners */
    background-color: #000000; /* Black background */
}

.quantityButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: white;
    transition: color 0.3s; /* Smooth transition for text color */
}

.quantityButton:hover {
    color: #999; /* Darker text color on hover */
}

.quantity {
    font-size: 12px;
    margin: 0 20px;
    padding-bottom: 2px;
    padding-top: 1px;
    color: white; /* Text color */
}

/* Remove button */
.removeButton {
    background-color: rgb(110, 110, 110); /* Red background */
    color: white;
    border: none;
    padding: 8px 15px; /* Adjust padding */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer;
    transition: background-color 0.3s; /* Smooth transition for background color */
    
}

.removeButton:hover {
    background-color: #d14646; /* Darker red on hover */
}

.removeButtonContainer {
    padding-left: 40%; /* Add padding to the remove button container */
}
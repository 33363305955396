.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: black;
    overflow-x: hidden;
    padding-top: 60px;
    transition: width 0.5s;
}

.sidenav a {
    text-decoration: none;
    font-size: 18px;
    color: white;
    display: block;
    transition: padding-left 0.5s, border-color 0.5s;
    padding-left: 24px;
    border-bottom: 1px solid transparent;
}

.sideBarItems {
    padding: 16px 0;
    margin: 8px 0;
}

.sidenav a:hover {
    padding-left: 32px;
    border-bottom-color: #ffffff;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
    cursor: pointer;
    width: "20px"
}

#main {
    transition: margin-left .5s;
    padding: 20px;
}

@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }

    .sidenav a {
        font-size: 18px;
    }
}


.bottomIcons {
    /* position: absolute; */
    margin-top: 20px;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    padding: 16px 0;
    background-color: #1a1a1a;
}

.icon {
    font-size: 20px;
    color: white;
    cursor: pointer;
    transition: color 0.3s;
}

.icon:hover {
    color: #f1f1f1;
}

.whiteIcon {
    color: white;
    cursor: pointer;
}
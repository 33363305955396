.order-table {
  width: 1200px; /* Adjust this value to your desired table width */
  border: 1px solid #eee;
  border-collapse: collapse;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
}

.order-table th,
.order-table td {
  border: 1px solid #eee;
  padding: 15px;
  width: 170px;
}

.order-table th {
  background-color: #f5f5f5;
  font-weight: bold;
  text-align: left;
}

.order-table img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

.order-table .ant-pagination {
  justify-content: flex-end;
  margin-top: 10px;
}

.order-table .ant-btn {
  margin: 0 5px;
  border-radius: 4px;
}

/* Media queries for responsive behavior */
@media (max-width: 768px) {
  .order-table {
    font-size: 14px; /* Adjust font size for smaller screens */
  }
  .order-table th,
  .order-table td {
    padding: 10px; /* Reduce padding on smaller screens */
  }
  .order-table .ant-pagination {
    justify-content: flex-end; /* Removed for more control */
    margin-top: 10px; /* Maintains top spacing */
    margin-right: 20px; /* Adds right margin for better placement */
  }
}

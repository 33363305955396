.productContainer {
  /* max-width: 1200px; */
  /* margin: 0 auto;
    padding: 15px; */
  display: flex;
  flex-direction: row;
  gap: 15px;
  width: 100%;
  font-family: "Roboto", sans-serif;
}

.left-column {
  padding-left: 20px;
  padding-right: 20px;
  width: 35%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  margin-bottom: 20px;
}

.right-column {
  width: 65%;
  margin-top: 60px;
  padding-left: 20px;
  padding-right: 20px;
}

.galleryImages {
  height: 100px;
  width: 66px;
  border: 2px solid rgb(197, 197, 197);
  border-radius: 5px;
  cursor: pointer;
  margin-top: 60px;
  /* left: 0;
    top: 0;
    transition: all 0.3s ease;
    align-items: center;
    justify-content: center; */
}

.selectedImage {
  height: 450px;
  width: auto;
  border: 2px solid black;
  border-radius: 5px;
  margin-top: 60px;
}

.active {
  border: 2px solid black;
}

.left-column img.active {
  opacity: 1;
}

.product-description {
  border-bottom: 1px solid #e1e8ee;
  margin-bottom: 20px;
}

.product-description span {
  font-size: 12px;
  color: #358ed7;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
}

.product-description h1 {
  font-weight: 300;
  font-size: 52px;
  color: #43484d;
  letter-spacing: -2px;
}

.product-description p {
  font-size: 16px;
  font-weight: 300;
  color: #86939e;
  line-height: 24px;
}

.product-color {
  margin-bottom: 30px;
}

.color-choose {
  margin-top: 10px;
}

.color-choose div {
  display: inline-block;
}

.color-choose input[type="radio"] {
  display: none;
}

.color-choose input[type="radio"] + label span {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: -1px 4px 0 0;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
}

.color-choose input[type="radio"]:checked + label span {
  background-repeat: no-repeat;
  background-position: center;
}

.stock-info {
  margin-top: 10px;
  margin-bottom: 10px;
  color: red;
  font-weight: bold;
}

.cable-choose {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
}

.cable-choose button {
  border: 2px solid #e1e8ee;
  border-radius: 6px;
  padding: 13px 20px;
  font-size: 14px;
  color: #5e6977;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.5s;
}

.cable-choose button:hover,
.cable-choose button:active,
.cable-choose button:focus {
  border: 2px solid #86939e;
  outline: none;
}

.cable-choose button.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.cable-choose button.selected {
  border: 2px solid #4caf50;
  color: #4caf50;
  font-weight: bold;
}

.expand-button {
  background-color: transparent;
  color: gray;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.long-description {
  max-height: 200px;
  /* Set maximum height for the description */
  overflow: hidden;
  /* Hide overflow content */
  transition: max-height 0.3s ease;
  /* Smooth transition animation */
}

.expanded {
  max-height: none;
  /* Expand to show all content */
}

.collapsed {
  max-height: 105px;
  /* Collapse back to maximum height */
}

.cable-config {
  border-bottom: 1px solid #e1e8ee;
  margin-bottom: 20px;
}

.cable-config a {
  color: #358ed7;
  text-decoration: none;
  font-size: 12px;
  position: relative;
  margin: 10px 0;
  display: inline-block;
}

.cable-config a:before {
  content: "?";
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 2px solid rgba(53, 142, 215, 0.5);
  display: inline-block;
  text-align: center;
  line-height: 16px;
  opacity: 0.5;
  margin-right: 5px;
}

.product-price {
  display: flex;
  align-items: center;
}

.product-price span {
  font-size: 26px;
  font-weight: 300;
  color: #43474d;
  margin-right: 20px;
}

.cart-btn {
  display: inline-block;
  background-color: #7dc855;
  border-radius: 6px;
  font-size: 16px;
  color: #ffffff;
  text-decoration: none;
  padding: 12px 30px;
  transition: all 0.5s;
  cursor: pointer;
}

.cart-btn-disabled {
  display: inline-block;
  background-color: #e1e8ee;
  border-radius: 6px;
  font-size: 16px;
  color: #ffffff;
  text-decoration: none;
  padding: 12px 30px;
  transition: all 0.5s;
  cursor: not-allowed;
}

.cart-btn:hover {
  background-color: #64af3d;
}

.galleryContainer {
  display: flex;
  flex-direction: column;
}

@media (max-width: 940px) {
  .productContainer {
    flex-direction: column;
    margin-top: 60px;
    align-items: center;
  }

  .galleryContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .left-column {
    /* flex-direction: column; */
    flex-direction: column-reverse;
    align-items: center;
  }

  .right-column {
    margin-bottom: 20px;
  }

  .left-column,
  .right-column {
    width: 80%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .galleryImages {
    height: 80px !important;
    width: 55px !important;
    margin: 0 !important;
  }

  .selectedImage {
    height: 450px !important;
    width: auto !important;
    margin: 0 !important;
  }
}

@media (max-width: 535px) {
  .left-column img {
    width: 220px;
    top: -85px;
  }
}

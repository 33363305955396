.product-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 20rem;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 2px 2px 5px 0px rgba(0, 64, 128, 0.1);
  text-decoration: none;
  cursor: pointer;
}

.product-card__image {
  max-width: 100%;
  height: auto;
}

.product-card__description,
.product-card__price,
.product-card__originalPrice,
.product-card__brand {
  margin: 0.2rem 0;
  font-size: 1rem;
  font-weight: bold;
}

.product-card__brand {
  /* font-weight: bold; */
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
  color: black;
  font-size: 16px;
}

.product-card__price {
  font-family: "Roboto", sans-serif;
  color: black;
  text-decoration: none;
  font-size: 16px;
}

.product-card__originalPrice {
  font-family: "Roboto", sans-serif;
  text-decoration: line-through;
  color: gray;
  font-size: 16px;
}

@media only screen and (max-width: 768px) {
  .product-card__brand {
    font-size: 12px;
  }
  .product-card__price {
    font-size: 12px;
  }
  .product-card__originalPrice {
    font-size: 12px;
  }

  .product-card__sold-out {
    font-size: 6px !important;
    /* padding: 3px 5px; */
    /* width: 20px; */
  }
  .product-card__btn-wishlist {
    height: 20px !important;
    width: 20px !important;
    padding: 3px 5px 5px !important;
  }
}

.product-card__btn-wishlist {
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  border: none;
  background-color: white;
  padding: 12px 10px 10px;
  box-shadow: 2px 2px 5px 0px rgba(0, 64, 128, 0.1);
  cursor: pointer;
}

.product-card__btn-wishlist svg {
  fill: lightgrey;
}

.product-card__sold-out {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px 10px;
  background-color: #e74c3c; /* Red color for sold out */
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.color-palette {
  margin: 5px 0;
  display: flex;
  align-items: center;
  gap: 5px;
}

.color-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #ddd;
}

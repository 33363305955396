body::-webkit-scrollbar {
  width: 12px;
  /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: transparent;
  /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(199, 199, 199);
  /* color of the scroll thumb */
  border-radius: 20px;
  /* roundness of the scroll thumb */
  border: 3px solid white;
  /* creates padding around scroll thumb */
}

body {
  font-family: "Roboto", sans-serif;
}

.page-full,
.page-width {
  padding: 0;
}

.page-width {
  max-width: 1500px;
  margin: 0 auto;
  position: relative;
  /* Ensure relative positioning for absolute positioning of images */
}

.loading-screen {
  position: absolute;
  top: 0;
  /* Position the loading screen at the top of the page */
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  /* Set background color to transparent */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loading-image {
  position: absolute;
  /* Position the image absolutely */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* filter: brightness(150%); */
  /* object-fit: contain; Adjust object-fit property as needed */
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  /* Semi-transparent white color */
}

.subscribe-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: black !important;
  color: white !important;
}

.subscribe-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px 20px 20px;
}

.subscribe-modal-content p {
  margin-bottom: 15px;
  font-size: 16px;
}

.subscribe-modal-content input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
}

.ant-modal-content {
  background-color: black !important;
  color: white !important;
}

.ant-modal-title {
  background-color: black !important;
  color: white !important;
}

.ant-modal-close-x {
  color: white !important;
}

.ant-btn:hover {
  color: black !important;
  border: 1px solid black !important;
}

.ant-modal-title {
  color: white !important;
  font-size: 24px !important;
  font-family: "Roboto", sans-serif !important;
  text-align: center;
  text-transform: uppercase;
}

body {
  color: darkslategray;
  overflow-x: hidden;
}

.popup-modal-content {
  width: 300px !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.popup-title {
  height: 40px;
  position: absolute;
  width: 100%;
  left: 0px;
  background-color: whitesmoke;
  top: 0px;
  text-align: center;
  padding-top: 5px;
  color: black;
}

.popup-content {
  margin-top: 40px;
  padding-top: 10px;
  width: 100%;
  text-align: center;
}

.popup-modal {
  height: 70px;
}

.left-margin {
  margin-left: 10px;
}

.toast-wrapper {
  position: fixed;
  top: 80px;
  right: 30px;
  z-index: 100 !important;
}

.toast {
  opacity: 0.95 !important;
  width: 250px;
}

.toast-header {
  color: dodgerblue !important;
}

.card-bottom-list {
  max-height: 303px;
}

.bg-login-image {
  /* background: url("./assets/login.png") !important; */
  background-position: center !important;
  background-size: cover !important;
}

.bg-gradient-primary-green {
  background-image: linear-gradient(180deg, #0e1e24 10%, #00c6b6 100%);
  background-size: cover;
}

.icon-green {
  color: seagreen;
}

.bg-custom-dark {
  background-color: #0e1e24;
}

.dark-breadcrumb {
  background-color: #0e1e24 !important;
  margin-top: 15px !important;
}

li a {
  color: cadetblue;
}

.cadet {
  color: cadetblue;
}

.text-green {
  color: cadetblue !important;
  font-family: sans-serif !important;
}

.toggle-area {
  display: none;
}

.collapsed {
  display: block;
}

.toggle-button {
  background-color: #0e1e24 !important;
  border-color: #0e1e24 !important;
  width: 40px;
  color: seagreen !important;
}

@media (max-width: 380px) {
  .collapsed {
    display: none !important;
  }

  .toggle-area {
    position: fixed;
    bottom: 2%;
    left: 2%;
    z-index: 200 !important;
    display: block;
  }
}

.flexx {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.contentwrapper {
  width: 100%;
  margin-left: 250px;
  overflow: auto;
  overflow-x: hidden;
}

@media (max-width: 768px) {
  .contentwrapper {
    margin-left: 80px;
  }
}

.pageswrapper {
  padding: 24px;
  width: 100%;
  min-height: 100%;
  max-height: 100%;
  overflow-y: hidden;
  min-width: 100%;
  max-width: 100%;
}

/* Full-screen preloader */
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  transition: opacity 0.5s ease-out;
}

.zipper-animation {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

/* Fade-out animation when loader disappears */
.fade-out {
  opacity: 0;
  pointer-events: none;
}

.loading-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.subscribe-btn {
  width: 50%;
  height: auto;
}

.subscribe-text {
  font-size: 20px;
  text-transform: uppercase;
  font-family: "Roboto", sans-serif;
}

.modal-text {
  font-family: "Roboto", sans-serif;
}

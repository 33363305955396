.sidebar {
    width: 15%;
    background-color: #F7FAFC;
    position: fixed;
    min-height: 100%;
    max-height: 100%;
    border-right: 1px solid #E2E8F0;
    overflow-y: auto;
    overflow-x: hidden;
}

.sidebarcollapsed {
    width: 80px;
    background-color: #F7FAFC;
    position: fixed;
    min-height: 100%;
    max-height: 100%;
    border-right: 1px solid #E2E8F0;
    overflow-y: auto;
    overflow-x: hidden;
}

.sidebar::-webkit-scrollbar {
    width: 3px;
}

.sidebar::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.sidebar::-webkit-scrollbar-thumb {
    background: #CBD5E0;
    border-radius: 5px;
}

.sidebar::-webkit-scrollbar-thumb:hover {
    background: rgb(160, 160, 160);
}

.sidebarcollapsed::-webkit-scrollbar {
    width: 3px;
}

.sidebarcollapsed::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.sidebarcollapsed::-webkit-scrollbar-thumb {
    background: #CBD5E0;
    border-radius: 5px;
}

.sidebarcollapsed::-webkit-scrollbar-thumb:hover {
    background: rgb(160, 160, 160);
}

.sidebar__logo {
    text-align: center;
    padding-top: 27px;
    padding-bottom: 23px;
    display: block;
}

.sidebar__logo img {
    /* width: 142px; */
    height: 35px;
}

.sidebar__logocollapsed img {
    width: 71px;
    height: 25px;
    margin-top: 40px;
    margin-left: 6px;
    margin-bottom: 10px;
}

.sidebar__userinfo {
    background: #fff;
    margin: 23px 18px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar__userinfo img {
    width: 40px;
    height: 40px;
}

.sidebar__userinfocollapsed {
    background: #fff;
    margin: 36px 18px 35px 18px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar__userinfocollapsed img {
    width: 40px;
    height: 40px;
}

.sidebar__userinfo__text {
    margin-left: 10px;
    padding-right: 23px;
    display: flex;
    flex-direction: column;
    min-width: 0;
}

.sidebar__userinfo__text__name {
    color: #6c738a;
    font-family: var(--font-500);
    font-weight: 500;
    font-size: 14px;
    line-break: anywhere;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sidebar__userinfo__text__email {
    color: #abafbd;
    font-size: 12px;
    font-weight: 400;
    line-break: anywhere;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sidebar__menu {
    width: 250;
    background-color: #F7FAFC;
    border: none;
    height: 100%;
}

.collapsebutton {
    margin-bottom: 16px;
}

.centered {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}
.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}

.background img {
  width: 100%;
  object-fit: contain;
}

.background .content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  padding: 20px;
}

.background .text {
  display: flex;
  flex-direction: column;
  position: absolute;
  /* Keep absolute positioning for layering */
  top: 25%;
  /* Position from the center vertically */
  transform: translateY(-90%);
  /* Offset to counteract 50% positioning */
  left: 35px;
  /* Maintain left margin */
  color: white;
  font-size: 18px;
  font-weight: bold;
  z-index: 10;
}

@media screen and (max-width: 916px) {
  .background .text {
    top: 12%;
    transform: translateY(-90%);
  }
}

@media screen and (max-width: 670px) {
  .background .text {
    top: 9%;
    transform: translateY(-90%);
  }
}

@media screen and (max-width: 490px) {
  .background .text {
    top: 8%;
    transform: translateY(-90%);
  }
}

@media screen and (max-width: 452px) {
  .background .text {
    top: 6.5%;
    transform: translateY(-90%);
  }
}

.buttonShop,
.buttonShop:after {
  cursor: pointer;
  width: 150px;
  height: 76px;
  line-height: 78px;
  font-size: 20px;
  font-family: "Bebas Neue", sans-serif;
  background: linear-gradient(45deg, transparent 5%, black 5%);
  border: 0;
  color: #fff;
  letter-spacing: 3px;
  box-shadow: 6px 0px 0px white;
  outline: transparent;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.buttonShop:after {
  --slice-0: inset(50% 50% 50% 50%);
  --slice-1: inset(80% -6px 0 0);
  --slice-2: inset(50% -6px 30% 0);
  --slice-3: inset(10% -6px 85% 0);
  --slice-4: inset(40% -6px 43% 0);
  --slice-5: inset(80% -6px 5% 0);

  content: "DETACH";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    45deg,
    transparent 3%,
    white 3%,
    white 5%,
    black 5%
  );
  text-shadow: -3px -3px 0px red, 3px 3px 0px white;
  clip-path: var(--slice-0);
}

.buttonShop:hover:after {
  animation: 1s glitch;
  animation-timing-function: steps(2, end);
}

@keyframes glitch {
  0% {
    clip-path: var(--slice-1);
    transform: translate(-20px, -10px);
  }

  10% {
    clip-path: var(--slice-3);
    transform: translate(10px, 10px);
  }

  20% {
    clip-path: var(--slice-1);
    transform: translate(-10px, 10px);
  }

  30% {
    clip-path: var(--slice-3);
    transform: translate(0px, 5px);
  }

  40% {
    clip-path: var(--slice-2);
    transform: translate(-5px, 0px);
  }

  50% {
    clip-path: var(--slice-3);
    transform: translate(5px, 0px);
  }

  60% {
    clip-path: var(--slice-4);
    transform: translate(5px, 10px);
  }

  70% {
    clip-path: var(--slice-2);
    transform: translate(-10px, 10px);
  }

  80% {
    clip-path: var(--slice-5);
    transform: translate(20px, -10px);
  }

  90% {
    clip-path: var(--slice-1);
    transform: translate(-10px, 0px);
  }

  100% {
    clip-path: var(--slice-1);
    transform: translate(0);
  }
}

@media (min-width: 768px) {
  .buttonShop,
  .buttonShop:after {
    width: 200px;
    height: 86px;
    line-height: 88px;
  }
}

.carousel-container img {
    max-width: 100%;
    height: auto;
  }
  
  .carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
  }
  
  
  .buttonContainer {
    position: absolute;
    bottom: 20px;
    /* Adjust as needed */
    left: 50%;
    transform: translateX(-50%);
  }
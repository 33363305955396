.newsBar {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    background-color: black;
    position: relative;
    text-align: center;
    font-size: calc(var(--typeBaseSize)*.85);
    max-width: 100%;
}

.newsText {
    color: white;
    text-transform: uppercase;
    letter-spacing: .2em;
    font-size: .9em;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
}

.header-wrapper--sticky {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 6;
    background: linear-gradient(180deg, rgba(0, 0, 0, .3) 0%, transparent);
}

.toolbar {
    background: var(--colorNav);
    background-color: transparent;
    color: #fff;
    border-bottom: none;
    font-size: calc(var(--typeBaseSize)*.85);
}

.logo {
    height: 70px;
    position: relative;
    left: 33px;
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    .toolbar {
        display: none !important;
    }

    .siteHeader {
        padding: 20px 0;
        margin-left: 20px;
        margin-right: 20px;
    }

    .logo {
        height: 30px;
        left: 0;
    }

    .hideInMob {
        display: none !important;
    }
}

.stickyHeader {
    height: 102px;
}

.toolbarContent {
    border-bottom: 1px solid hsla(0, 0%, 0%, 0.2);
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.toolbarItem {
    flex: 0 1 auto;
    padding: 0 5px;
}

.toolbarItem:first-child {
    padding-left: 0;
}

.inlineList {
    padding: 0;
    margin: 0;
    text-align: right;
}

.inlineList li {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
}

.inlineList a {
    display: block;
    padding: 5px;
    position: relative;
    top: -2px;
    width: 16px;
    height: 16px;
}

.icon {
    position: relative;
    top: -2px;
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: middle;
    fill: #000;
}

.iconFallbackText {
    clip: rect(0, 0, 0, 0);
    overflow: hidden;
    position: absolute;
    height: 1px;
    width: 1px;
}

.siteHeader {
    background: none;
    box-shadow: none;
    position: relative;
    padding: 7px 0;
    background: var(--colorNav);
}

.headerLayout {
    display: flex;
    justify-content: space-between;
    padding: 0 40px;
}

@media screen and (max-width: 768px) {
    .headerLayout {
        padding: 0 10px;
    }

}



.headerItem {
    display: flex;
    align-items: center;
}

.headerIcons {
    justify-content: flex-end;
    cursor: pointer;
}

.headerIcons {
    color: black;
    width: 28px;
    height: 28px;
}

.rightHeader {
    display: flex;
    align-items: center;
    gap: 20px;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.searchBar {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: stretch;
    padding: 0 40px;
    height: 70px;
}

.searchBar input {
    width: 100%;
    padding: 10px;
    border: none;
    outline: none;
    font-size: 16px;
}